'use strict'

###*
 # @ngdoc service
 # @name ColorManager.factory:ColorManager

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'ColorManager', [
    '$q'
    'Restangular'
    '$http'
    'mundoConfiguration'
    (
      $q
      Restangular
      $http
      mundoConfiguration
    ) ->
      ColorManagerBase = {
        transport: Restangular
      }

      ColorManagerBase.one = (code) ->
        @transport.one('colors/palettes', code).get()

      ColorManagerBase.getColorPalleteByReport = (configid,shareid, accessToken) ->
        $http.defaults.headers.common['Authorization'] = "Bearer " + accessToken
        $http({
          url: "#{mundoConfiguration.oauth.baseUrl}/api/open/colors/palettes/reports/#{configid}/shares/#{shareid}"
          method: 'GET'
        })

      ColorManagerBase
  ]
